import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';

import { setSelectMenuStyle } from '../../../../../../../../bi/utils/account';

import { TRIPSLABELS, FIELDS } from '../../../../../../../../bi/constants/trips';

import styles from '../../../../../../styles/form.module.scss';

const LABELS = {
  TYPE: 'search',
  DISABLED: 'disabled',
};

const HotelCountry = ({
  groupHotels: {
    disableLocation,
    JsonData: data,
    validation,
  },
  tripService: {
    autocompleteCountry,
    hotelTripFieldValidation,
    setValidationGroupHotel,
  },
  onValidateInput,
  onChangeJsonInput,
}) => {
  const [suggestCountries, setSuggestCountries] = useState([]);

  const { Hotel: { Location } } = data;
  const { Hotel: { Location: validationLocation } } = validation;
  const disableStyle = disableLocation ? LABELS.DISABLED : '';
  const wrapperStyle = { style: styles.wrap_auto_input };
  const inputProps = {
    className: `${styles.input} ${validationLocation ? styles.not_valid_input : ''}`,
    type: LABELS.TYPE,
  };
  const messageHtml = validationLocation && <span className={ styles['error-msg'] }>{ validationLocation }</span>;

  const items = suggestCountries;

  const handleSelectLocationAutocomplete = ({ name, code }) => {
    onChangeJsonInput(FIELDS.HOTEL_LOCATION, name);
    onChangeJsonInput(FIELDS.HOTEL_COUNTRY_CODE, code);
    onValidateInput(FIELDS.HOTEL_LOCATION);
  };

  const renderItem = (item) => {
    if (!item) {
      return null;
    }

    return (
      <div className={ styles['autocomplete-item'] }>
        {`${item.name}`}
      </div>
    );
  };

  const handleChangeLocationAutocomplete = async (value) => {
    if (disableLocation) return;

    onChangeJsonInput(FIELDS.HOTEL_LOCATION, value);

    const result = hotelTripFieldValidation(FIELDS.HOTEL_LOCATION, '');

    setValidationGroupHotel(FIELDS.HOTEL_LOCATION, result);

    const suggest = await autocompleteCountry(value);

    setSuggestCountries(value ? suggest : []);
  };

  return (
    <div className={ styles['col-1-3'] }>
      <div className={ `${styles['auto-input']} ${styles['autocomplete-label']} ${styles[disableStyle]}` }>
        <label>{ TRIPSLABELS.COUNTRY }</label>
        <Autocomplete
          menuStyle={ setSelectMenuStyle(suggestCountries) }
          value={ Location }
          items={ items }
          getItemValue={ item => item.name }
          onSelect={ (value, item) => handleSelectLocationAutocomplete(item) }
          onChange={ ({ target: { value } }) => handleChangeLocationAutocomplete(value) }
          inputProps={ inputProps }
          wrapperStyle={ wrapperStyle }
          renderItem={ renderItem }
        />
        { messageHtml }
      </div>
    </div>
  );
};

HotelCountry.propTypes = {
  groupHotels: PropTypes.object.isRequired,
  onValidateInput: PropTypes.func.isRequired,
  onChangeJsonInput: PropTypes.func.isRequired,
  tripService: PropTypes.object.isRequired,
};

export { HotelCountry };
