import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../../../../components/checkbox';
import AjaxButton from '../../../../components/ajaxButton';

import styles from '../../styles/trip.module.scss';

class DialogForm extends Component {
  static propTypes = {
    // eslint-disable-next-line
    users: PropTypes.any,
    // eslint-disable-next-line
    handlerConfirmUsers: PropTypes.any,
  };

  constructor() {
    super();

    this.state = {
      usersEmail: [],
      waitingResponse: false,
    };
  }

  handleChangeCheckbox = (item, value) => {
    const usersEmail = this.state.usersEmail;
    const result = usersEmail.indexOf(item.Email);

    if (value) {
      usersEmail.push(item.Email);
    } else {
      usersEmail.splice(result, 1);
    }

    this.setState({
      usersEmail,
    });
  };

  handleConfirm = () => {
    const confirm = () => {
      this.props.handlerConfirmUsers(this.state.usersEmail)
        .then(() => this.setState({ waitingResponse: false }))
        .catch(() => this.setState({ waitingResponse: false }));
    };

    this.setState({ waitingResponse: true }, confirm);
  };

  render() {
    const {
      users,
    } = this.props;
    const {
      usersEmail,
      waitingResponse,
    } = this.state;

    const checkboxHtml = users.map((item, ind) => (
      <Checkbox
        field='Email'
        label={ `${item.Email}` }
        value={ usersEmail.indexOf(item.Email) !== -1 }
        key={ ind }
        onChange={ (e, field, value) => this.handleChangeCheckbox(item, value) }
      />
    ));

    return (
      <div className={ styles['user-dialog'] }>
        <span className={ styles['user-dialog__name'] }>Выберите пользователя</span>
        { checkboxHtml }
        <div className={ styles.action }>
          <AjaxButton
            label='Отправить'
            loading={ waitingResponse }
            onClick={ this.handleConfirm }
          />
        </div>
      </div>
    );
  }
}

export default DialogForm;
