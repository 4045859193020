import React from 'react';
import PropTypes from 'prop-types';

import Input from '../../../../../../../../components/input';

import { TRIPSLABELS, FIELDS } from '../../../../../../../../bi/constants/trips';
import { numbersAndColon, timePattern } from '../../../../../../../../bi/constants/regExp';

import styles from '../../../../../../styles/form.module.scss';

const ERRORS = {
  INVALID_CHARACTER: 'недопустимые символы',
  INVALID_FORMAT: 'недопустимый формат',
};

const HotelCheckinCheckout = ({
  groupHotels: {
    JsonData: data,
    validation,
  },
  onChangeJsonInput,
  tripService: {
    setValidationGroupHotel,
  },
}) => {
  const { Hotel: { CheckinTime, CheckoutTime } } = data;
  const { Hotel: { CheckinTime: validationCheckinTime, CheckoutTime: validationCheckoutTime } } = validation;

  const handleChangeTimeInput = (field, value) => {
    if (value.length === 0 || numbersAndColon.test(value)) {
      setValidationGroupHotel(field, '');
      onChangeJsonInput(field, value);
    } else {
      setValidationGroupHotel(field, ERRORS.INVALID_CHARACTER);
    }
  };

  const handleBlurTimeInput = (field, value) => {
    if (value.length === 0 || timePattern.test(value)) {
      setValidationGroupHotel(field, '');
    } else {
      setValidationGroupHotel(field, ERRORS.INVALID_FORMAT);
    }
  };

  return (
    <div className={ styles.row }>
      <div className={ styles['col-attention'] }>
        <Input
          field={ FIELDS.HOTELCHECKINTIME }
          value={ CheckinTime }
          label={ TRIPSLABELS.HOTELCHECKINTIME }
          onChange={ ({ target: { value } }) => handleChangeTimeInput(FIELDS.HOTELCHECKINTIME, value) }
          onBlur={ ({ target: { value } }) => handleBlurTimeInput(FIELDS.HOTELCHECKINTIME, value) }
          valid={ validationCheckinTime }
        />
      </div>
      <div className={ styles['col-attention'] }>
        <Input
          field={ FIELDS.HOTELCHECKOUTTIME }
          value={ CheckoutTime }
          label={ TRIPSLABELS.HOTELCHECKOUTTIME }
          onChange={ ({ target: { value } }) => handleChangeTimeInput(FIELDS.HOTELCHECKOUTTIME, value) }
          onBlur={ ({ target: { value } }) => handleBlurTimeInput(FIELDS.HOTELCHECKOUTTIME, value) }
          valid={ validationCheckoutTime }
        />
      </div>
    </div>
  );
};

HotelCheckinCheckout.propTypes = {
  onChangeJsonInput: PropTypes.func.isRequired,
  groupHotels: PropTypes.object.isRequired,
  tripService: PropTypes.object.isRequired,
};

export { HotelCheckinCheckout };
