import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';
import { Dialog, Button } from 'sw-ui';

import ContractHotel from '../../../../../../components/ContractHotel';
import { EditMainGroupBooking } from '../../EditMainGroupBooking';
import { StarsHotel } from './components/StarsHotel';
import { HotelName } from './components/HotelName';
import { HotelAddress } from './components/HotelAddress';
import { HotelCity } from './components/HotelCity';
import { HotelPhone } from './components/HotelPhone';
import { HotelEmail } from './components/HotelEmail';

import { TRIPSLABELS, FIELDS } from '../../../../../../bi/constants/trips';
import COMPONENTS from '../../../../../../bi/constants/components';
import { HOTEL_PROVIDER_VALUE } from '../../../../../../bi/constants/hotel';

import styles from '../../../../styles/form.module.scss';

const MENUSTYLE = {
  position: 'absolute',
  left: 0,
  top: 40,
  display: 'flex',
  flexDirection: 'column',
  minWidth: '200px',
  boxShadow: '0 1px 3px rgba(0, 0, 0, .15)',
};

const LABELS = {
  ADD_HOTEL: 'Добавить отель',
  TEXT_ADD_HOTEL: 'Вы уверены, что отеля нет в выпадающем списке?',
  WITHOUT_STARS: 'Без звезд',
  YES: 'Да',
  NO: 'Нет',
  CANCELLATION_CONDITION: 'Условия отмены',
  UPDATE_CANCELLATION_CONDITION: 'Обновите условия штрафов',
  SELECT_MEAL: 'Выберите питание',
  RATE_SW: 'Собственный тариф смартвэй',
  PERIOD: 'Период',
};

const AutocompleteHotel = ({
  disabledFieldStyles,
  groupHotels: {
    showModal,
    showModalEditMainHotelFields,
    hotelName,
    validation,
  },
  groupHotels,
  funcProps: {
    onValidateInput,
    onChangeJsonInput,
  },
  funcProps,
  tripService,
}) => {
  const handleAddHotel = value => tripService.changeFieldGroupBooking(FIELDS.SHOW_MODAL, value);

  const handleChangeSuggest = (value) => {
    tripService.setGroupHotelName(value);

    const result = tripService.hotelTripFieldValidation(FIELDS.HOTELNAMEUPPER, value);

    tripService.setValidationGroupHotel(FIELDS.HOTELNAMEUPPER, result);
  };

  const getContractValue = (isConnected, contract) => {
    if (isConnected && !contract) {
      return HOTEL_PROVIDER_VALUE.travelline;
    }

    if (!isConnected && contract) {
      return HOTEL_PROVIDER_VALUE.contract;
    }
  };

  const getContractAndConnectHotel = async ({ Id }) => {
    const { getContractConnectCompareHotel, changeJsonInputGroupHotel, setContractConnectCompareGroupBooking } = tripService;
    const { contract, connected, compared } = await getContractConnectCompareHotel(Id);
    const { JsonData } = groupHotels;

    if (connected.length) {
      const isConnected = connected[0].Connected;
      const value = getContractValue(isConnected, contract);

      changeJsonInputGroupHotel(FIELDS.PROVIDERNAME, value, JsonData);
    }

    setContractConnectCompareGroupBooking({ contract, connected, compared });
  };

  const getTLRates = async ({ Id }) => {
    const { getTravellineRates, setRatesGroupBooking } = tripService;
    const { JsonData: { ProviderName } } = groupHotels;


    if (ProviderName === HOTEL_PROVIDER_VALUE.travelline) {
      const { rates } = await getTravellineRates(Id);

      setRatesGroupBooking(rates);
    }
  };

  const handleSelectSuggest = (field, value, hotel) => {
    tripService.autocompleteGroupBookingDetails(hotel);

    getContractAndConnectHotel(hotel);
    getTLRates(hotel);
  };

  const handleConfirmationChangeHotel = () => tripService.resetMainHotelFields();

  const renderHotelNameAutocomplete = () => {
    const { Hotel: { Name: validationName } } = validation;
    const { label, suggests } = hotelName;

    const renderItemHtml = ({ Id, Name, FullName }) => (
      <div key={ Id } className={ styles.autocomplete_item }>
        <div className={ styles.autocomplete_item_text }>{ Name }</div>
        <div className={ styles.autocomplete_item_label }>{ FullName }</div>
      </div>
    );

    const validHotelNameHtml = validationName && <span className={ `error-msg ${styles['error-msg-company']}` }>
        { validationName }
    </span>;

    const styleValidation = validationName ? styles['no-valid-hotel-name'] : '';

    return (
      <div className={ styles.row }>
        <div className={ styles['col-7-8'] }>
          <label>{ TRIPSLABELS.SELECT_HOTEL }</label>
          <div className={ `${styles['auto-input']} ${disabledFieldStyles} ${styles.wrap} ${styleValidation}` }>
            <Autocomplete
              menuStyle={ MENUSTYLE }
              value={ label }
              items={ suggests }
              getItemValue={ item => item.Name }
              onSelect={ (value, hotel) => handleSelectSuggest(FIELDS.HOTELNAME, value, hotel) }
              onChange={ ({ target: { value } }) => handleChangeSuggest(value) }
              renderItem={ item => renderItemHtml(item) }
            />
            <ContractHotel
              contract={ groupHotels.contract }
              connected={ groupHotels.connected }
              compared={ groupHotels.compared }
            />
            { validHotelNameHtml }
          </div>
        </div>
        <div className={ styles['col-1-8'] }>
          <Button
            label={ LABELS.ADD_HOTEL }
            theme={ COMPONENTS.BUTTON.THEME.SECOND }
            className={ styles.save_button }
            onClick={ () => handleAddHotel(true) }
          />
        </div>
      </div>
    );
  };

  const renderConfirmAddHotel = () => (
    <Dialog
      onClick={ () => handleAddHotel(false) }
      width={ COMPONENTS.DIALOG.WIDTH.MIDDLE }
    >
      <div className={ styles.dialog }>
        <div>{ LABELS.TEXT_ADD_HOTEL }</div>
        <div className={ styles.dialog_buttons }>
          <Button
            label={ LABELS.YES }
            theme={ COMPONENTS.BUTTON.THEME.SECOND }
            onClick={ handleConfirmationChangeHotel }
          />
          <Button
            label={ LABELS.NO }
            theme={ COMPONENTS.BUTTON.THEME.FLAT }
            onClick={ () => handleAddHotel(false) }
          />
        </div>
      </div>
    </Dialog>
  );

  const renderEditMainHotelFields = () => (
    <EditMainGroupBooking
      groupHotels={ groupHotels }
      tripService={ tripService }
      funcProps={ funcProps }
    />
    );

  return (
    <div>
      <div className={ styles['col-1-3'] }>
        { renderHotelNameAutocomplete() }
      </div>
      <div className={ styles.row }>
        <HotelName
          onValidateInput={ onValidateInput }
          groupHotels={ groupHotels }
          tripService={ tripService }
          onChangeJsonInput={ onChangeJsonInput }
        />
        <div className={ styles['col-1-6'] }>
          <div className={ styles.select }>
            <StarsHotel
              onChangeJsonInput={ onChangeJsonInput }
              groupHotels={ groupHotels }
            />
          </div>
        </div>
      </div>
      <HotelAddress
        onValidateInput={ onValidateInput }
        onChangeJsonInput={ onChangeJsonInput }
        groupHotels={ groupHotels }
      />
      <div className={ styles.row }>
        <HotelCity
          onValidateInput={ onValidateInput }
          onChangeJsonInput={ onChangeJsonInput }
          groupHotels={ groupHotels }
          tripService={ tripService }
        />
        <HotelPhone
          onChangeJsonInput={ onChangeJsonInput }
          groupHotels={ groupHotels }
        />
      </div>
      <HotelEmail
        groupHotels={ groupHotels }
        onChangeJsonInput={ onChangeJsonInput }
        tripService={ tripService }
      />
      { showModal && renderConfirmAddHotel() }
      { showModalEditMainHotelFields && renderEditMainHotelFields() }
    </div>
  );
};

AutocompleteHotel.propTypes = {
  disabledFieldStyles: PropTypes.string.isRequired,
  funcProps: PropTypes.object.isRequired,
  groupHotels: PropTypes.object.isRequired,
  tripService: PropTypes.object.isRequired,
};

export { AutocompleteHotel };
