import React from 'react';
import PropTypes from 'prop-types';

import Input from '../../../../../../../../components/input';

import { TRIPSLABELS, FIELDS } from '../../../../../../../../bi/constants/trips';

import styles from '../../../../../../styles/form.module.scss';

const HotelEmail = ({
  groupHotels: {
    disableMainHotelFields,
    JsonData: data,
  },
  onChangeJsonInput,
  tripService: {
    changeFieldGroupBooking,
  },
}) => {
  const { Hotel: { Email } } = data;

  const handleChange = (value) => {
    onChangeJsonInput(FIELDS.HOTEL_EMAIL, value);
    changeFieldGroupBooking(FIELDS.CONTACT, value);
  };

  return (
    <div className={ styles.row }>
      <div className={ styles['col-1-2'] }>
        <Input
          field={ FIELDS.CONTACT }
          value={ Email }
          label={ TRIPSLABELS.CONTACT }
          onChange={ ({ target: { value } }) => handleChange(value) }
          disabled={ disableMainHotelFields }
        />
      </div>
    </div>
  );
};

HotelEmail.propTypes = {
  onChangeJsonInput: PropTypes.func.isRequired,
  groupHotels: PropTypes.object.isRequired,
  tripService: PropTypes.object.isRequired,
};

export { HotelEmail };
