import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, RadioButton } from 'sw-ui';

import { CANCELAIRFIELDS } from '../../../../bi/constants/trips';
import COMPONENTS from '../../../../bi/constants/components';

import styles from '../../styles/penalty.module.css';

const LABELS = {
  BUTTON: 'Далее',
};

export default class SelectCancelAir extends Component {
  static propTypes = {
    onChangeStep: PropTypes.func.isRequired,
  };

  state = {
    checked: CANCELAIRFIELDS.ALL,
  };

  handleChange = value => this.setState({
    checked: value,
  });

  render() {
    const { checked } = this.state;

    return (
      <div>
        <div className={ `${styles.row} ${styles.title}` }>
          Параметры штрафа
        </div>
        <div>
          <RadioButton
            checked={ checked === CANCELAIRFIELDS.ALL }
            onChange={ () => this.handleChange(CANCELAIRFIELDS.ALL) }
          >
            Отменить весь билет
          </RadioButton>
          <RadioButton
            className={ styles.penalty_radiobutton }
            checked={ checked === CANCELAIRFIELDS.SEGMENTS }
            onChange={ () => this.handleChange(CANCELAIRFIELDS.SEGMENTS) }
          >
            Отменить сегменты
          </RadioButton>
        </div>
        <div className={ `${styles.row} ${styles.penalty_action}` }>
          <Button
            label={ LABELS.BUTTON }
            theme={ COMPONENTS.BUTTON.THEME.FLAT }
            onClick={ () => this.props.onChangeStep(checked) }
          />
        </div>
      </div>
    );
  }
}
