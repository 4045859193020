import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'sw-ui';

import { isShowTaxes, isShowTaxesClue } from '../../../../bi/utils/air';

import COMPONENTS from '../../../../bi/constants/components';

import Price from '../air/components/Price';
import Vat from '../air/components/Vat';

import styles from '../../styles/penalty.module.css';

export default class CancelSegmentAir extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    cancelSegment: PropTypes.object.isRequired,
    vatDetails: PropTypes.array.isRequired,
    segments: PropTypes.array.isRequired,
    onChangePrice: PropTypes.func.isRequired,
    onChangeVatDetails: PropTypes.func.isRequired,
    onChangeCheckbox: PropTypes.func.isRequired,
  };

  handleChangeCheckbox = (index, value) => {
    const { segments, onChangeCheckbox } = this.props;

    const changedSegments = segments.map((item) => {
      let selected = item.Selected;

      if (item.SegmentIndex === index) {
        selected = value;
      }

      return {
        ...item,
        Selected: selected,
      };
    });

    const hasCancelAll = segments.length === changedSegments.every(({ Selected }) => !Selected).length;

    onChangeCheckbox(changedSegments, hasCancelAll);
  };

  renderCheckboxSegment = () => {
    const { segments } = this.props;

    return segments.map(({ Segment: { Direction }, SegmentIndex, Selected }) => (
      <div key={ `checkbox_segment_${SegmentIndex}` } className={ styles.penalty__cancel_checkbox }>
        <Checkbox
          theme={ COMPONENTS.CHECKBOX.THEME.MAIN }
          value={ Selected }
          onChange={ value => this.handleChangeCheckbox(SegmentIndex, value) }
        >
          { Direction }
        </Checkbox>
      </div>
    ));
  };

  renderPrices = () => {
    const {
      cancelSegment: { Refund },
      onChangePrice,
      item: {
        ProviderName,
        JsonData,
      },
    } = this.props;
    const { ProviderInfo } = JSON.parse(JsonData);
    const showTaxes = isShowTaxes({ ProviderName, ProviderInfo });
    const showTaxesClue = isShowTaxesClue({ ProviderName });

    return (
      <Price
        showTaxes={ showTaxes }
        showTaxesClue={ showTaxesClue }
        isCancelled
        priceDetails={ Refund }
        onChangeInput={ onChangePrice }
      />
    );
  };

  renderVat = () => {
    const { item, vatDetails, onChangeVatDetails } = this.props;

    const itemVat = {
      VatDetails: vatDetails,
      ProviderName: item.ProviderName,
    };

    return (
      <Vat
        isFundsForm
        isEditing
        airline={ item }
        keyItem={ 0 }
        ticket={ itemVat }
        handleChangeVatDetails={ onChangeVatDetails }
      />
    );
  };

  render() {
    const vatHtml = this.props.item.VatDetails.length ? this.renderVat() : null;

    return (
      <div>
        <div className={ styles.penalty__cancel_segments }>Сегменты</div>
        { this.renderCheckboxSegment() }
        <div className={ styles.penalty__cancel_title }>Возврат</div>
        { this.renderPrices() }
        { vatHtml }
      </div>
    );
  }
}
