import React from 'react';
import PropTypes from 'prop-types';

import Input from '../../../../../../../../components/input';

import { TRIPSLABELS, FIELDS } from '../../../../../../../../bi/constants/trips';

import styles from '../../../../../../styles/form.module.scss';

const HotelPhone = ({
  groupHotels: {
    disableMainHotelFields,
    JsonData: data,
  },
  onChangeJsonInput,
}) => {
  const { Hotel: { Phone } } = data;

  return (
    <div className={ styles['col-1-2'] }>
      <Input
        field={ FIELDS.HOTELPHONE }
        value={ Phone }
        label={ TRIPSLABELS.PHONE }
        onChange={ ({ target: { value } }) => onChangeJsonInput(FIELDS.HOTELPHONE, value) }
        disabled={ disableMainHotelFields }
      />
    </div>
  );
};

HotelPhone.propTypes = {
  onChangeJsonInput: PropTypes.func.isRequired,
  groupHotels: PropTypes.object.isRequired,
};

export { HotelPhone };
