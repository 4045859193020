import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';

import Input from '../../../../../../../../components/input';

import { setSelectMenuStyle } from '../../../../../../../../bi/utils/account';

import { TRIPSLABELS, FIELDS } from '../../../../../../../../bi/constants/trips';

import styles from '../../../../../../styles/form.module.scss';

const LABELS_TYPE = 'search';

const HotelCity = ({
  groupHotels: {
    disableMainHotelFields,
    JsonData: data,
    validation,
  },
  tripService: {
    autocompleteRegion,
    changeFieldGroupBooking,
    getRegion,
  },
  onValidateInput,
  onChangeJsonInput,
}) => {
  const [suggestCities, setSuggestCities] = useState([]);

  const { Hotel: { City } } = data;
  const { Hotel: { City: validationCity } } = validation;
  const wrapperStyle = { style: styles.wrap_auto_input };
  const inputProps = {
    className: `${styles.input} ${validationCity ? styles.not_valid_input : ''}`,
    type: LABELS_TYPE,
  };

  const messageHtml = validationCity && <span className={ styles['error-msg'] }>{ validationCity }</span>;

  const renderItem = (item) => {
    if (!item) {
      return null;
    }

    return (
      <div className={ styles['autocomplete-item'] }>
        {`${item.City}`}
      </div>
    );
  };

  const handleChangeAutocomplete = async (value) => {
    onChangeJsonInput(FIELDS.HOTELCITY, value);
    changeFieldGroupBooking(FIELDS.DISABLE_LOCATION, false);

    const suggest = await autocompleteRegion(value);

    setSuggestCities(value ? suggest : []);

    onValidateInput(FIELDS.HOTELCITY);
  };

  const handleSelectAutocomplete = async ({ City: CityName, RegionId }) => {
    const responce = await getRegion(RegionId);

    const location = responce ? responce.CountryNameRu : '';
    const countryCode = responce ? responce.CountryCode : '';

    onChangeJsonInput(FIELDS.HOTEL_LOCATION, location);
    onChangeJsonInput(FIELDS.HOTELCITY, CityName);
    onChangeJsonInput(FIELDS.HOTEL_REGION_ID, RegionId);
    onChangeJsonInput(FIELDS.HOTEL_COUNTRY_CODE, countryCode);
    changeFieldGroupBooking(FIELDS.DISABLE_LOCATION, true);

    onValidateInput(FIELDS.HOTEL_LOCATION);
  };

  const renderCityInp = () => (
    <Input
      field={ FIELDS.HOTELCITY }
      value={ City }
      label={ TRIPSLABELS.CITY }
      onChange={ ({ target: { value } }) => onChangeJsonInput(FIELDS.HOTELCITY, value) }
      onBlur={ () => onValidateInput(FIELDS.HOTELCITY) }
      valid={ validationCity }
      disabled={ disableMainHotelFields }
    />
  );

  const renderAutocompleteCity = () => (
    <div>
      <label>{ TRIPSLABELS.CITY }</label>
      <Autocomplete
        menuStyle={ setSelectMenuStyle(suggestCities) }
        value={ City }
        items={ suggestCities }
        getItemValue={ item => item.City }
        onSelect={ (value, item) => handleSelectAutocomplete(item) }
        onChange={ ({ target: { value } }) => handleChangeAutocomplete(value) }
        inputProps={ inputProps }
        wrapperStyle={ wrapperStyle }
        renderItem={ renderItem }
      />
      { messageHtml }
    </div>
  );

  const renderOutput = () => (disableMainHotelFields ? renderCityInp() : renderAutocompleteCity());

  return (
    <div className={ styles['col-1-3'] }>
      { renderOutput() }
    </div>
  );
};

HotelCity.propTypes = {
  groupHotels: PropTypes.object.isRequired,
  onValidateInput: PropTypes.func.isRequired,
  onChangeJsonInput: PropTypes.func.isRequired,
  tripService: PropTypes.object.isRequired,
};

export { HotelCity };
