import React from 'react';
import PropTypes from 'prop-types';

import Input from '../../../../../../../../components/input';

import { TRIPSLABELS, FIELDS } from '../../../../../../../../bi/constants/trips';

import styles from '../../../../../../styles/form.module.scss';

const HotelAddress = ({
  groupHotels: {
    disableMainHotelFields,
    JsonData: data,
    validation,
  },
  onValidateInput,
  onChangeJsonInput,
}) => {
  const { Hotel: { Address } } = data;
  const { Hotel: { Address: validationAddress } } = validation;

  return (
    <div>
      <div className={ styles.row }>
        <div className={ styles['col-1'] }>
          <Input
            field={ FIELDS.HOTELADDRESS }
            value={ Address }
            label={ TRIPSLABELS.ADDRESS }
            onChange={ ({ target: { value } }) => onChangeJsonInput(FIELDS.HOTELADDRESS, value) }
            onBlur={ () => onValidateInput(FIELDS.HOTELADDRESS) }
            valid={ validationAddress }
            disabled={ disableMainHotelFields }
          />
        </div>
      </div>
    </div>
  );
};

HotelAddress.propTypes = {
  onValidateInput: PropTypes.func.isRequired,
  onChangeJsonInput: PropTypes.func.isRequired,
  groupHotels: PropTypes.object.isRequired,
};

export { HotelAddress };
